<template>
    <div class="app-container">
        <div style="width:800px;margin:0 auto">
            <el-steps :active="active" align-center>
                <el-step title="基础信息"></el-step>
                <el-step title="经营信息"></el-step>
                <el-step title="结算信息"></el-step>
                <el-step title="收益配置"></el-step>
                <el-step title="返现配置"></el-step>
            </el-steps>
        </div>
        <BaseInfo @change="getBaseInfo" :provinceList="provinceList" v-show="active==0"></BaseInfo>
        <BusinessInfo @change="getBusinessInfo" :legalName="legalName" v-show="active==1"></BusinessInfo>
        <SettleInfo @change="getSettleInfo" :companyName="companyName" :legalName="legalName" :provinceList="provinceList" v-show="active==2"></SettleInfo>
        <BenefitConfig @change="getBenefitConfig" :parentAgentNo="parentAgentNo" :show="true" v-show="active==3"></BenefitConfig>
        <CashBackConfig @change="getCashBackConfig" :parentAgentNo="parentAgentNo" :show="true" v-show="active==4"></CashBackConfig>
        <el-dialog
            title="入网成功"
            :visible.sync="dialogVisible"
            width="40%"
            :show-close="false"
            :close-on-click-modal="false">
            <p>{{addTips}}</p>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleClose">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import BaseInfo from '@/components/agentUpdateBase/baseInfo'
import BusinessInfo from '@/components/agentUpdateBase/businessInfo'
import SettleInfo from '@/components/agentUpdateBase/settleInfo'
import BenefitConfig from '@/components/agentUpdateBase/benefitConfig'
import CashBackConfig from '@/components/agentUpdateBase/cashBackConfig'
import { BasicApi,AgentApi } from '@/api'
export default {
    data() {
        return {
            active: 0,
            provinceList: [],
            parentAgentNo: 0,
            legalName: '',
            companyName: '',
            params: {},
            addTips:'',
            dialogVisible: false,
        }
    },
    components:{
        BaseInfo,
        BusinessInfo,
        SettleInfo,
        BenefitConfig,
        CashBackConfig
    },
    created(){
       BasicApi.listProvinceCity()
            .then(res=>{
                if(res.success){
                    this.provinceList = res.data
                    this.$forceUpdate()
                }
            })
    },
    methods: {
        getBaseInfo(params,type){
            debugger
            if(type=='next'){
                this.active ++
                this.params.baseInfo = params
                this.parentAgentNo = params.parentAgentNo ? params.parentAgentNo: '0'
                this.legalName = params.legalName
            } if(type=='prev'){
                this.active --
                 params.parentAgentNo = params.parentAgentNo == '0' ? '' :params.parentAgentNo
            }
            
        },
        getBusinessInfo(params,type){
            if(type=='next'){
                this.active ++
                this.params.businessInfo = params
                this.companyName = params.companyName
            } if(type=='prev'){
                this.active --
            }
        },
        getSettleInfo(params,type){
            if(type=='next'){
                this.active ++
                this.params.setterInfo = params
            } if(type=='prev'){
                this.active --
            }
        },
        getBenefitConfig(params,type){
            if(type=='next'){
                this.active ++
                this.params.policyInfoList = params
            } if(type=='prev'){
                this.active --
            }
        },
        getCashBackConfig(params,type){
            if(type=='submit'){
                this.params.agentActivityInfoDoList = params
                this.params.channelNoList = this.params.baseInfo.channelNo
                console.log(this.params)
                AgentApi.saveOrg(this.params)
                    .then(res=>{
                        if(res.success){
                            this.addTips = res.data.tip
                            this.dialogVisible = true
                        } else{
  
                        }
                    })
            } if(type=='prev'){
                this.active --
            }
        },
        handleClose() {
            this.dialogVisible = false
            this.$router.push({
                name:'organizationQuery'
            })
        }
    },
}
</script>